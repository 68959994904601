export const QUIZZES = "QUIZZES";
export const EVENT_RESULTS = "EVENT_RESULTS";

export const QUIZ_INTRO = "QUIZ_INTRO";
export const NAME_TEAMS = "NAME_TEAMS";
export const MEET_TEAMS = "MEET_TEAMS";
export const ROUND_INTRO = "ROUND_INTRO";
export const QUESTION = "QUESTION";
export const ANSWERS = "ANSWERS";
export const QUESTION_RESULTS = "QUESTION_RESULTS";
export const FACTS = "FACTS";
export const ROUND_RESULTS = "ROUND_RESULTS";
export const ROUND_CONVERSATION = "ROUND_CONVERSATION";
export const QUIZ_RESULTS = "QUIZ_RESULTS";
export const TIEBREAKER = "TIEBREAKER";
export const TIEBREAKER_ANSWERS = "TIEBREAKER_ANSWERS";
export const TIEBREAKER_RESULTS = "TIEBREAKER_RESULTS";
export const QUIZ_CONVERSATION = "QUIZ_CONVERSATION";

export const MAX_AUTO_UNMUTE = 7;

export const DRAFT = "DRAFT";
export const REVIEW = "REVIEW";
export const APPROVED = "APPROVED";
export const ARCHIVED = "ARCHIVED";

export const friendlyStatus = {
  [DRAFT]: "Draft",
  [REVIEW]: "Review",
  [APPROVED]: "Approved",
  [ARCHIVED]: "Archived",
};
