import React from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import ActionBar from "../components/OldActionBar";
import Button from "../components/buttons/Button";
import { navigate } from "gatsby-link";
import Link from "../components/Link";
import PageWrapper from "../components/PageWrapper";
import queryString from "query-string";
import { APPROVED } from "../constants";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_ALL_ROUND_TEMPLATES = gql`
  query GetAllRoundTemplates {
    roundTemplates: getAllRoundTemplates {
      id
      title
      status
    }
  }
`;

const RoundTemplateListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RoundTemplateListingWrapper = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

export default function QuizList() {
  const { quizId, eventId } = queryString.parse(location.search);

  const { data } = useQuery(GET_ALL_ROUND_TEMPLATES);

  if (!data) {
    return null;
  }

  const roundTemplates = data.roundTemplates;

  const handleCancel = () => {
    if (eventId) {
      navigate(`/create-edit-event?eventId=${eventId}`);
    } else {
      navigate(`/create-edit-quiz?quizId=${quizId}`);
    }
  };

  const approvedRoundTemplates = roundTemplates.filter(
    (rt) => rt.status === APPROVED
  );

  return (
    <PageWrapper>
      <DarkPageHeading>Select an Approved Round</DarkPageHeading>
      <RoundTemplateListContainer>
        {approvedRoundTemplates.map((rt) => (
          <RoundTemplateListingWrapper key={rt.id}>
            <Link
              to={`/preview-round-template?quizId=${quizId}&roundTemplateId=${
                rt.id
              }${eventId ? "&eventId=" + eventId : ""}`}
            >
              {rt.title}
            </Link>
          </RoundTemplateListingWrapper>
        ))}
      </RoundTemplateListContainer>
      <ActionBar>
        <Button size="large" onClick={handleCancel}>
          Cancel
        </Button>
      </ActionBar>
    </PageWrapper>
  );
}
